import React, { useMemo, useState } from 'react';
import ChatSettingsComponent from './ChatSettingsComponent';
import { Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import './ChatSettings.css';
import { IChatSetting } from '../../types/apiPayloads';

const ChatSettingsContainer: React.FC = () => {
  const { chatSettings, handleCancelEditing } = useChatSettings();
  const [selectedSettingId, setSelectedSettingId] = useState<string | null>(
    chatSettings[0]?.id || null,
  );

  const sortedByUpdatedAtSettings = useMemo(() => {
    return chatSettings.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
  }, [chatSettings]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleCancelEditing();
    setSelectedSettingId(event.target.value);
  };

  const selectedSetting: IChatSetting =
    chatSettings.find(setting => setting.id === selectedSettingId) ||
    sortedByUpdatedAtSettings[0];

  return (
    <>
      <div className='mobile-only d-lg-none mb-3'>
        <Form.Select
          onChange={handleSelectChange}
          value={selectedSettingId || ''}
          className='d-lg-none mb-3'
        >
          {sortedByUpdatedAtSettings.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </Form.Select>
        {selectedSetting && <ChatSettingsComponent prompt={selectedSetting} />}
      </div>
      <Tab.Container
        id='left-tabs'
        defaultActiveKey={sortedByUpdatedAtSettings[0]?.id}
      >
        <Row className='d-none d-lg-flex'>
          <Col sm={3}>
            <Nav variant='pills' className='flex-column'>
              {chatSettings.map(({ id, title }) => (
                <Nav.Item key={id}>
                  <Nav.Link eventKey={id} onClick={handleCancelEditing}>
                    {title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {chatSettings.map(setting => (
                <Tab.Pane key={setting.id} eventKey={setting.id}>
                  <ChatSettingsComponent prompt={setting} />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default ChatSettingsContainer;
