// src/pages/ChatPage/ChatPage.tsx
import React, { useEffect, useState } from 'react';
import './ChatPage.css';
import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import ThreadsMenu from '../../components/ThreadsMenu/ThreadsMenu';
import ChatWindow from '../../components/ChatWindow/ChatWindow';
import NavBar from '../../components/shared/NavBar';
import { useChats } from '../../contexts/ChatsContext';
import { useAuth } from '../../contexts/AuthContext';

const ChatPage = () => {
  const { redirectToLogin } = useAuth();
  const { handleActiveChatChange } = useChats();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    redirectToLogin();
  }, []);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  return (
    <Container className='chat-page-container'>
      <NavBar />
      <Row className='h-100'>
        <div className='mobile-buttons-container d-lg-none'>
          <span
            onClick={handleShow}
            className='threads-button floating-button d-lg-none material-symbols-outlined'
          >
            settings
          </span>
          <span
            onClick={() => handleActiveChatChange('newChat')}
            className='floating-button new-chat-button d-lg-none material-symbols-outlined'
          >
            add
          </span>
        </div>
        <Col lg={2} className='col d-none d-lg-block overflow-y-scroll'>
          <ThreadsMenu onSelect={handleClose} />
        </Col>
        <Col lg={10} className='col'>
          <ChatWindow />
        </Col>
      </Row>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleClose}
        className='d-lg-none'
      >
        <Offcanvas.Header style={{ padding: '20px' }} closeButton>
          <Offcanvas.Title>Threads</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: '20px' }}>
          <ThreadsMenu onSelect={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default ChatPage;
