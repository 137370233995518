import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  IChatSetting,
  IChatSettingsContext,
  IPrompt,
} from '../types/apiPayloads';
import {
  createChatSettings,
  deleteAllChats,
  deleteChatSettings,
  getChatSettings,
  updateChatSettings,
} from '../utils/api';
import { useErrors } from './ErrorsContext';
import { useAuth } from './AuthContext';

const ChatSettingsContext: React.Context<IChatSettingsContext> =
  createContext<IChatSettingsContext>({} as IChatSettingsContext);

function ChatSettingsProvider({ children }: { children: ReactNode }) {
  const [chatSettings, setChatSettings] = useState<IChatSetting[]>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [editableSetting, setEditableSetting] = useState<IChatSetting>();
  const [editingState, setEditingState] = useState<boolean>(false);
  const { setApiError } = useErrors();
  const { isLoggedIn } = useAuth();

  const loggedIn = useCallback(() => {
    return isLoggedIn();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!loggedIn()) {
      return;
    }
    const fetchData = async () => {
      setLoadingState(true);
      try {
        const newData: IChatSetting[] = await getChatSettings();
        setChatSettings(newData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setApiError({ message: error.message, name: 'API error' });
        } else {
          console.log('Unknown error');
        }
      }
      setLoadingState(false);
    };

    fetchData();
  }, [loggedIn]);

  const handleCreateChatSettings = async (prompt: IPrompt) => {
    setLoadingState(true);
    try {
      const savedPrompt: IChatSetting = await createChatSettings(prompt);
      setChatSettings((prevChatSettings: IChatSetting[]) => [
        ...prevChatSettings,
        savedPrompt,
      ]);
      setEditingState(false);
      setLoadingState(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
      } else {
        console.log('Unknown error');
      }
    }
  };

  const handleUpdateChatSettings = async (prompt: IPrompt): Promise<void> => {
    setLoadingState(true);
    if (!editableSetting) {
      return;
    }
    try {
      const savedSetting: IChatSetting = await updateChatSettings(
        editableSetting.id,
        prompt,
      );
      const updatedChatSettings = chatSettings.map(setting =>
        setting.id === editableSetting.id ? savedSetting : setting,
      );
      setChatSettings(updatedChatSettings);
      setEditableSetting(undefined);
      setEditingState(false);
      setLoadingState(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
      } else {
        console.log('Unknown error');
      }
    }
  };

  const handleDeleteChatSettings = async (
    promptId: string,
  ): Promise<boolean> => {
    setLoadingState(true);
    try {
      const success: boolean = await deleteChatSettings(promptId);
      if (success) {
        setLoadingState(false);
        setChatSettings(chatSettings.filter(({ id }) => id !== promptId));
      }
      setLoadingState(false);
      return success;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
        setLoadingState(false);

        return false;
      } else {
        console.log('Unknown error');
        return false;
      }
    }
  };

  // delete all chats by setting id
  async function handleDeleteAllChats(id: string): Promise<boolean> {
    setLoadingState(true);
    let setting_id = id;
    if (!setting_id) {
      setting_id = '';
    } else if (setting_id === 'other') {
      setting_id = 'none';
    }
    try {
      const success: boolean = await deleteAllChats(setting_id);
      if (success) {
        setLoadingState(false);
        setApiError({ message: 'All chats deleted', name: 'Info' });
      }
      return success;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
        setLoadingState(false);

        return false;
      } else {
        console.log('Unknown error');
        return false;
      }
    }
  }

  const handleEditChatSetting = (chatSettingId: string) => {
    const setting = chatSettings.find(({ id }) => id === chatSettingId);
    setEditableSetting(setting);
    setEditingState(true);
  };

  const handleCancelEditing = () => {
    setEditingState(false);
    setEditableSetting(undefined);
  };

  return (
    <ChatSettingsContext.Provider
      value={{
        chatSettings,
        loadingState,
        editingState,
        editableSetting,
        handleNewChatSetting: () => setEditingState(true),
        handleCreateChatSettings,
        handleUpdateChatSettings,
        handleDeleteChatSettings,
        handleEditChatSetting,
        handleCancelEditing,
        handleDeleteAllChats,
      }}
    >
      {children}
    </ChatSettingsContext.Provider>
  );
}

function useChatSettings(): IChatSettingsContext {
  return useContext(ChatSettingsContext);
}

export { ChatSettingsProvider, useChatSettings };
