import React from 'react';
import { IChatSetting } from '../../types/apiPayloads';
import {
  chatsCountLabel,
  getModelIcon,
  respectNewLines,
} from '../../utils/helpers';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './ChatSettings.css';
import '../../App.css';
import { useChatSettings } from '../../contexts/ChatSettingsContext';

const ChatSettingsComponent: React.FC<{
  prompt: IChatSetting;
}> = ({ prompt }) => {
  const { handleDeleteChatSettings, handleEditChatSetting } = useChatSettings();
  const { handleDeleteAllChats } = useChatSettings();

  const confirmAndDeleteChatSettings = (id: string, chatsCount: number) => {
    if (!id) return;
    const message =
      chatsCount === 0
        ? 'Are you sure you want to delete this setting?'
        : `Are you sure you want to delete the chat setting and its ${chatsCountLabel(chatsCount)}?`;
    if (window.confirm(message)) {
      handleDeleteChatSettings(id);
    }
  };

  const confirmAndDeleteChats = (
    id: string | undefined,
    chatsCount: number,
  ) => {
    if (!id) return;
    const message = `Are you sure you want to delete ${chatsCountLabel(chatsCount)}?`;
    if (window.confirm(message)) {
      handleDeleteAllChats(id);
    }
  };

  return (
    <Card>
      <Card.Header
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Card.Title style={{ marginRight: '10px' }}>
          <>
            {getModelIcon(prompt.model_name)}
            <span style={{ marginRight: '10px' }} />
            {prompt.title}{' '}
          </>
        </Card.Title>
        <Card.Subtitle className='text-muted'>
          {`(${chatsCountLabel(prompt.chats_count)})`}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body style={{ minHeight: '20vh' }}>
        <div className='icon-buttons'>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-edit`}>Edit</Tooltip>}
          >
            <Button
              variant='link'
              onClick={() => handleEditChatSetting(prompt.id)}
              style={{ marginRight: '0' }}
            >
              <span className='material-symbols-outlined action-icon'>
                edit
              </span>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
          >
            <Button
              variant='link'
              onClick={() =>
                confirmAndDeleteChatSettings(prompt.id, prompt.chats_count)
              }
              style={{ marginLeft: '0' }}
            >
              <span className='material-symbols-outlined action-icon delete-button'>
                delete
              </span>
            </Button>
          </OverlayTrigger>
          {prompt.chats_count > 0 && (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-delete-sweep`}>Delete all chats</Tooltip>
              }
            >
              <Button
                variant='link'
                onClick={() =>
                  confirmAndDeleteChats(prompt.id, prompt.chats_count)
                }
                style={{ marginLeft: '0' }}
              >
                <span className='material-symbols-outlined action-icon delete-button'>
                  delete_sweep
                </span>
              </Button>
            </OverlayTrigger>
          )}
        </div>
        <Card.Text>{respectNewLines(prompt.system_prompt)}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ChatSettingsComponent;
