import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import {
  IChat,
  IChatMessage,
  IChatSetting,
  IncomingMessage,
  IPrompt,
} from '../types/apiPayloads';
import { handleAxiosError } from './helpers';

const BASE_URL: string = 'https://multigpt.tulentsev.com/api';

// Set up Axios instance with default configuration
const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // Set timeout
});

const noAuthEndpoints: string[] = ['auth/login', 'auth/signup'];

const jwtToken = () => {
  return localStorage.getItem('jwtToken') || '';
};

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token: string = jwtToken();
    if (!token || (config.url && !noAuthEndpoints.includes(config.url))) {
      config.headers = config.headers || {};
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  },
);

// Example login API request
const login = async (username: string, password: string): Promise<unknown> => {
  let response: AxiosResponse;
  try {
    response = await api.post('/auth/login', { username, password });
    localStorage.setItem('jwtToken', response.data['token']);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

const signup = async (username: string, password: string): Promise<unknown> => {
  let response: AxiosResponse;
  try {
    response = await api.post('/auth/signup', { username, password });
    localStorage.setItem('jwtToken', response.data['token']);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<unknown> => {
  let response: AxiosResponse;
  try {
    response = await api.post('/auth/update-password', {
      old_password: oldPassword,
      new_password: newPassword,
    });
    localStorage.setItem('jwtToken', response.data['token']);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const getChatSettings = async (): Promise<IChatSetting[]> => {
  let response: AxiosResponse;
  try {
    response = await api.get('/chat_settings');
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const createChatSettings = async (prompt: IPrompt): Promise<IChatSetting> => {
  let response: AxiosResponse;
  try {
    response = await api.post('/chat_settings', prompt);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const updateChatSettings = async (
  id: string,
  prompt: IPrompt,
): Promise<IChatSetting> => {
  let response: AxiosResponse;
  try {
    response = await api.put(`/chat_settings/${id}`, prompt);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const deleteChatSettings = async (id: string): Promise<boolean> => {
  let response: AxiosResponse;
  try {
    response = await api.delete(`/chat_settings/${id}`);
    return response.status === 200;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const getChats = async (): Promise<IChat[]> => {
  let response: AxiosResponse;
  try {
    response = await api.get('/chats');
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const deleteChat = async (id: string): Promise<boolean> => {
  let response: AxiosResponse;
  try {
    response = await api.delete(`/chats/${id}`);
    return response.status === 200;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const deleteAllChats = async (chat_settings_id: string): Promise<boolean> => {
  let response: AxiosResponse;
  try {
    response = await api.delete('/chats/bulk-delete', {
      data: { chat_settings_id },
    });
    return response.status === 200;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const sendMessage = async (message: IChatMessage): Promise<IncomingMessage> => {
  let response: AxiosResponse;
  try {
    response = await api.post('/messages', message);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const getMessages = async (chatId: string): Promise<IncomingMessage[]> => {
  let response: AxiosResponse;
  try {
    response = await api.get(`/messages?chat_id=${chatId}`);
    return response.data;
  } catch (error: unknown) {
    return handleAxiosError(error);
  }
};

const initializeEventSource = (
  onMessage: (newMessage: IncomingMessage) => void,
  onError: (error: Event) => void,
  onProgress: (chatId: string) => void,
  onChatInfo: (chat: IChat) => void,
): EventSource => {
  const evtSource = new EventSource(`${BASE_URL}/events?token=${jwtToken()}`);

  evtSource.addEventListener('message', event => {
    const newMessage = JSON.parse(event.data);
    onMessage(newMessage);
  });

  evtSource.addEventListener('error', event => {
    onError(event);
  });

  evtSource.addEventListener('processing', event => {
    const { chat_id: chatId } = JSON.parse(event.data);
    onProgress(chatId);
  });

  evtSource.addEventListener('chat', event => {
    const chat = JSON.parse(event.data);
    onChatInfo(chat);
  });
  return evtSource;
};

export {
  BASE_URL,
  initializeEventSource,
  getMessages,
  sendMessage,
  deleteChat,
  deleteAllChats,
  getChats,
  deleteChatSettings,
  getChatSettings,
  login,
  createChatSettings,
  updateChatSettings,
  signup,
  changePassword,
};
