import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavBar from '../../components/shared/NavBar';
import UserMenu from '../../components/UserMenu/UserMenu';
import './HowToPage.css';

const TuneMultiGPTPage: React.FC = () => {
  return (
    <Container>
      <NavBar />
      <Row>
        <Col xs={2}>
          <UserMenu />
        </Col>
        <Col xs={10}>
          <Container className='py-3'>
            <h2 className='page-header'>How to Tune MultiGPT</h2>
            <p className='justified-text'>
              When creating a prompt for ChatGPT to tune its response style,
              it&apos;s important to clearly define the desired tone, formality,
              and specific instructions or guidelines you want it to follow.
              This input serves as a template to guide the AI on how to generate
              the responses that align with your expectations.
            </p>
            <h3 className='section-header'>Example:</h3>
            <dl className='row justified-text'>
              <dt className='col-sm-2'>Tone</dt>
              <dd className='col-sm-10'>Friendly and conversational.</dd>
              <dt className='col-sm-2'>Formality</dt>
              <dd className='col-sm-10'>
                Informal but respectful. Friendly and conversational.
              </dd>
              <dt className='col-sm-2'>Instructions</dt>
              <dd className='col-sm-10'>
                Include humor and analogies to simplify complex topics. Avoid
                technical jargon and repetitive phrases.
              </dd>
            </dl>
            <h3 className='section-header'>Explanation:</h3>
            <p className='justified-text'>
              By setting the tone to &quot;friendly and conversational&quot; and
              formality to &quot;informal but respectful&quot;, you are asking
              ChatGPT to create responses that are approachable and easy to
              understand. Including instructions to use &quot;humor and
              analogies&quot; helps to make complex topics more digestible.
              Avoiding &quot;technical jargon&quot; ensures that explanations
              remain accessible to all users, while steering clear of
              &quot;repetitive phrases&quot; maintains the originality and
              clarity of responses.
            </p>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default TuneMultiGPTPage;
