import React, { FormEvent, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useErrors } from '../../contexts/ErrorsContext';

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [formError, setFormError] = useState({ message: '', name: '' });
  const [, setValidated] = useState<boolean>(false);

  const { updatePassword, authError, resetAuthError } = useAuth();
  const { setApiError } = useErrors();

  const passwordsMatch = () => {
    if (!passwordConfirmation || !newPassword) return true;
    return newPassword === passwordConfirmation;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!customCheckValidity()) {
      event.stopPropagation();
    } else {
      setValidated(true);
      const isSuccess = await updatePassword(oldPassword, newPassword);
      if (isSuccess) {
        resetAuthError();
        setApiError({
          message: 'Password updated successfully.',
          name: 'Info',
        });
        clearForm();
      } else {
        setValidated(false);
      }
    }
  };

  const clearForm = () => {
    setOldPassword('');
    setNewPassword('');
    setPasswordConfirmation('');
    setFormError({ message: '', name: '' });
  };

  const customCheckValidity = () => {
    if (passwordsMatch()) {
      setFormError({ message: '', name: '' });
      return true;
    } else {
      setFormError({ message: 'Passwords do not match', name: 'Form error' });
      return false;
    }
  };
  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col sm={10} lg={4}>
          <Form onSubmit={handleSubmit}>
            {authError.message.length > 0 && (
              <Alert variant='danger'>{authError.message}</Alert>
            )}
            <Form.Group className='mb-3'>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type='password'
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type='password'
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                required
                isInvalid={!passwordsMatch()}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type='password'
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                required
                isInvalid={!passwordsMatch()}
              />
              <Form.Control.Feedback type='invalid'>
                {formError.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePasswordForm;
