import { Accordion, Card, Nav } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useChats } from '../../contexts/ChatsContext';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import { IChatSetting } from '../../types/apiPayloads';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import './ThreadsMenu.css';

const ThreadsMenu: React.FC<{
  onSelect: () => void;
}> = ({ onSelect }) => {
  const {
    activeChatId,
    chatsGroupedBySettingsId,
    handleActiveChatChange,
    handleDeleteChat,
    activeSettingsId,
  } = useChats();
  const { chatSettings } = useChatSettings();
  const [activeKeys, setActiveKeys] = useState<string[]>(
    activeSettingsId ? [activeSettingsId] : [],
  );

  const isActive = (id: string) => {
    return activeChatId === id;
  };

  useEffect(() => {
    setActiveKeys(activeSettingsId ? [activeSettingsId] : []);
  }, [activeSettingsId]);

  const handleAccordionToggle = (key: AccordionEventKey) => {
    if (typeof key === 'string') {
      setActiveKeys(prevKeys =>
        prevKeys.includes(key)
          ? prevKeys.filter(k => k !== key)
          : [...prevKeys, key],
      );
    }
  };

  const handleAccordionSelect = (chatId: string) => {
    handleActiveChatChange(chatId);
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <Nav
      navbar
      defaultActiveKey='newChat'
      className='flex-column col-scrollable'
      onSelect={selectedKey =>
        handleAccordionSelect(selectedKey ? selectedKey : 'newChat')
      }
    >
      <Accordion
        flush
        activeKey={activeKeys}
        onSelect={handleAccordionToggle}
        style={{ width: '100%' }}
      >
        <Card.Header className='accordion-item'>
          <button
            type='button'
            style={{
              pointerEvents: isActive('newChat') ? 'none' : 'auto',
              textDecoration: 'none',
            }}
            className={`accordion-button static-header ${isActive('newChat') ? 'active' : 'collapsed'}`}
            onClick={() => handleActiveChatChange('newChat')}
          >
            <span className='material-symbols-outlined add-button'>add</span>
            <span style={{ marginRight: '7px' }} />
            New Chat
          </button>
        </Card.Header>

        {chatSettings.map((setting: IChatSetting) => (
          <Accordion.Item key={setting.id} eventKey={setting.id}>
            <Accordion.Header>{setting.title}</Accordion.Header>
            <Accordion.Body>
              {chatsGroupedBySettingsId[setting.id]?.map(({ id, title }) => (
                <Nav.Item
                  key={id}
                  className='d-flex align-items-center position-relative'
                >
                  <Nav.Link
                    eventKey={id}
                    active={isActive(id)}
                    className={`menu-item ${isActive(id) ? 'active' : ''}`}
                    style={{
                      pointerEvents: isActive(id) ? 'none' : 'auto',
                      fontWeight: isActive(id) ? 'bold' : 'normal',
                    }}
                  >
                    {title}
                  </Nav.Link>
                  {!isActive(id) && (
                    <span
                      className='position-absolute action-icon delete-button'
                      style={{ top: '10px', right: '-15px' }}
                      onClick={() => handleDeleteChat(id)}
                    >
                      <span className='material-symbols-outlined'>delete</span>
                    </span>
                  )}
                </Nav.Item>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
        {chatsGroupedBySettingsId['Other']?.length > 0 && (
          <Accordion.Item eventKey='Other'>
            <Accordion.Header>Other</Accordion.Header>
            <Accordion.Body>
              {chatsGroupedBySettingsId['Other'].map(({ id, title }) => (
                <Nav.Item
                  key={id}
                  className='d-flex align-items-center position-relative'
                >
                  <Nav.Link
                    eventKey={id}
                    active={isActive(id)}
                    className={`menu-item ${isActive(id) ? 'active' : ''}`}
                    style={{
                      pointerEvents: isActive(id) ? 'none' : 'auto',
                      fontWeight: isActive(id) ? 'bold' : 'normal',
                    }}
                  >
                    {title}
                  </Nav.Link>
                  {!isActive(id) && (
                    <span
                      className='position-absolute action-icon delete-button'
                      style={{ top: '10px', right: '-15px' }}
                      onClick={() => handleDeleteChat(id)}
                    >
                      <span className='material-symbols-outlined'>delete</span>
                    </span>
                  )}
                </Nav.Item>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </Nav>
  );
};

export default ThreadsMenu;
