import React, { useEffect, useMemo, useRef, useState } from 'react';
import Message from './Message';
import { IncomingMessage } from '../../types/apiPayloads';
import { useChats } from '../../contexts/ChatsContext';
import MessagePlaceholder from './MessagePlaceholder';

const MessageList = () => {
  const { activeChatMessages, messageLoading } = useChats();

  const [messages, setMessages] = useState<IncomingMessage[]>([]);

  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMessages(activeChatMessages);
  }, [activeChatMessages]);

  // scroll to the beginning of the last message on message send/receive and on load chat
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: messageLoading ? 'auto' : 'smooth',
      });
    }
  }, [messages]);

  const lastMessage = useMemo(() => {
    return messages.at(-1);
  }, [messages]);

  return (
    <>
      {messages.map(({ text, id, role }) => (
        <div key={id}>
          {!messageLoading && id === lastMessage?.id && role !== 'user' && (
            <div ref={bottomRef} />
          )}
          <Message text={text} userInput={role === 'user'} username={role} />
          {!messageLoading && id === lastMessage?.id && role === 'user' && (
            <div ref={bottomRef} />
          )}
        </div>
      ))}
      {messageLoading && (
        <>
          <MessagePlaceholder username='assistant' />
          <div ref={bottomRef} />
        </>
      )}
    </>
  );
};
export default MessageList;
