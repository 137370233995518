import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const LoginForm = () => {
  const [validated, setValidated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { logIn, authError, resetAuthError } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      resetAuthError();
    };
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setValidated(true);
    const isSuccess = await logIn(username, password);
    if (isSuccess) {
      navigate('/chat');
    } else {
      setValidated(false);
      console.log('Error on submit', authError);
    }
  };

  const handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit} validated={validated}>
      {authError.message.length > 0 && (
        <Alert variant='danger' dismissible>
          {authError.message}
        </Alert>
      )}
      <Form.Group style={{ marginBottom: '20px', alignItems: 'center' }}>
        <Form.Text className='text-muted'>
          Enter your details to continue.
        </Form.Text>
      </Form.Group>
      <Form.Group className='mb-3' controlId='formBasicLogin'>
        <Form.Control
          type='text'
          placeholder='Username'
          onChange={handleLoginChange}
          required
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Control
          type='password'
          placeholder='Password'
          onChange={handlePasswordChange}
          required
        />
      </Form.Group>
      <Button variant='primary' type='submit'>
        Login
      </Button>
      {/*<Form.Group style={{ marginTop: '20px', alignItems: 'center' }}>*/}
      {/*  <Form.Text className='text-muted'>*/}
      {/*    Don&apos;t have an account? Sign up <Link to='/signup'>here</Link>*/}
      {/*  </Form.Text>*/}
      {/*</Form.Group>*/}
    </Form>
  );
};

export default LoginForm;
