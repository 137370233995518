import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Header: React.FC<{ header: string; subheader: string | undefined }> = ({
  header,
  subheader,
}) => {
  return (
    <Row style={{ padding: '20px' }}>
      <Col sm='12' style={{ textAlign: 'center' }}>
        <h1>{header}</h1>
        <p>{subheader}</p>
      </Col>
    </Row>
  );
};

export default Header;
