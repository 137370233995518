import React, { createContext, ReactNode, useContext, useState } from 'react';
import { IAuthContext } from '../types/apiPayloads';
import { changePassword, login, signup } from '../utils/api';
import useErrorState from '../hooks/useErrorState';

const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>(
  {} as IAuthContext,
);

function AuthProvider({ children }: { children: ReactNode }) {
  const [authError, setAuthError] = useErrorState({ message: '', name: '' });
  const [loadingState, setLoadingState] = useState<boolean>(false);

  async function signUp(username: string, password: string): Promise<boolean> {
    setLoadingState(true);
    try {
      await signup(username, password);
      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setAuthError({ message: error.message, name: 'AUTH error' });
      } else {
        console.log('Unknown error');
      }
      return false;
    } finally {
      setLoadingState(false);
    }
  }

  async function logIn(username: string, password: string): Promise<boolean> {
    setLoadingState(true);
    try {
      await login(username, password);
      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setAuthError({ message: error.message, name: 'AUTH error' });
      } else {
        console.log('Unknown error');
      }
      return false;
    } finally {
      setLoadingState(false);
    }
  }

  async function updatePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<boolean> {
    try {
      await changePassword(oldPassword, newPassword);
      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setAuthError({ message: error.message, name: 'AUTH error' });
      } else {
        console.log('Unknown error');
      }
      return false;
    }
  }

  const isLoggedIn = (): boolean => {
    const token = localStorage.getItem('jwtToken');
    return token !== null && token.trim().length > 0;
  };

  function logOut(): void {
    localStorage.removeItem('jwtToken');
    window.location.href = '/login';
  }

  function redirectToLogin(): void {
    if (!isLoggedIn()) {
      localStorage.setItem(
        'authError',
        'You must be logged in to access this page',
      );
      window.location.href = '/login';
    }
  }

  function redirectToChat(): void {
    if (isLoggedIn()) {
      window.location.href = '/chat';
    }
  }

  function resetAuthError(): void {
    setAuthError({ message: '', name: '' });
  }

  return (
    <AuthContext.Provider
      value={{
        signUp,
        logIn,
        authError,
        loadingState,
        isLoggedIn,
        redirectToLogin,
        logOut,
        resetAuthError,
        updatePassword,
        redirectToChat,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): IAuthContext {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
