import { Badge } from 'react-bootstrap';
import React from 'react';
import JumpingDots from '../shared/JumpingDots';

const MessagePlaceholder: React.FC<{ username: string }> = ({ username }) => (
  <div key='0' className='my-2'>
    <Badge pill bg={'primary'}>
      {username}
    </Badge>
    <JumpingDots />
  </div>
);

export default MessagePlaceholder;
