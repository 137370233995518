import { useState } from 'react';

function useErrorState(initialValue: Error) {
  const [error, setError] = useState<Error>(initialValue);

  const updateError = (newError: Partial<Error>) => {
    setError(prevError => ({
      ...prevError,
      ...newError,
    }));
  };

  return [error, updateError] as const;
}

export default useErrorState;
