import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import clipboardCopy from 'clipboard-copy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MarkdownCopyProps {
  markdown: string;
}

const MarkdownCopy: React.FC<MarkdownCopyProps> = ({ markdown }) => {
  const markdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const preTags = markdownRef.current?.querySelectorAll('pre');

    preTags?.forEach(preTag => {
      const link = document.createElement('a');
      const icon = document.createElement('span');
      icon.className = 'material-symbols-outlined';
      icon.innerText = 'markdown_copy';

      link.appendChild(icon);

      link.href = '#';
      link.style.position = 'absolute';
      link.style.top = '20px';
      link.style.right = '15px';
      link.style.color = '#9a9a9e';
      link.style.textDecoration = 'none';
      link.style.cursor = 'pointer';

      link.addEventListener('click', () => {
        link.style.color = '#66ae63';
        clipboardCopy(preTag.innerText).catch(err => {
          console.error('Failed to copy code block:', err);
        });
      });

      const wrapper = document.createElement('div');
      wrapper.style.position = 'relative';

      preTag.parentNode?.insertBefore(wrapper, preTag);
      wrapper.appendChild(preTag);
      wrapper.appendChild(link);
    });
  }, [markdown]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const components: Partial<ReactMarkdown.MarkdownProps['components']> = {
    code({
      inline,
      className,
      children,
      ...props
    }: {
      inline: boolean;
      className?: string;
      children: React.ReactNode;
    }) {
      const match = /language-(\w+)/.exec(className || '');
      let language = match ? match[1] : '';
      // highlight javascript as jsx
      if (language === 'javascript' || language === 'js') {
        language = 'jsx';
      }
      return !inline && match ? (
        <SyntaxHighlighter
          style={darcula}
          language={match[1]}
          PreTag='div'
          customStyle={{
            padding: '20px',
            borderRadius: '10px',
          }}
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <div ref={markdownRef}>
      <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
    </div>
  );
};

export default MarkdownCopy;
