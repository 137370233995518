import React from 'react';
import ChangePasswordForm from '../../components/ChatSettingsForm/ChangePasswordForm';
import NavBar from '../../components/shared/NavBar';
import Header from '../../components/shared/Header';
import { Container } from 'react-bootstrap';

const ChangePasswordPage = () => {
  return (
    <Container>
      <NavBar />
      <Header
        header='Change password'
        subheader='Use strong password to make sure your account is secure.'
      />
      <ChangePasswordForm />
    </Container>
  );
};

export default ChangePasswordPage;
