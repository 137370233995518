import './ChatWindow.css';
import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import MessageList from './MessageList';
import ChatForm from './ChatForm';

const ChatWindow = () => {
  return (
    <Container
      className='py-3 d-flex h-100'
      style={{ flexDirection: 'column' }}
    >
      <Row
        style={{
          height: '100%',
          flexGrow: '1',
          overflowY: 'auto',
        }}
      >
        <Col className='p-3 mb-3'>
          <MessageList />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <ChatForm />
        </Col>
      </Row>
      <Row className='d-lg-none' style={{ height: '10%' }} />
    </Container>
  );
};

export default ChatWindow;
