import ChatSettingsContainer from './ChatSettingsContainer';
import { Button, Container } from 'react-bootstrap';
import React from 'react';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import PromptForm from './PromptForm';
import './ChatSettings.css';

const ChatSettings: React.FC = () => {
  const {
    loadingState,
    editingState,
    handleNewChatSetting,
    handleDeleteAllChats,
  } = useChatSettings();

  const confirmAndDelete = (action: string) => {
    if (window.confirm(`Are you sure to ${action}?`)) {
      handleDeleteAllChats(action);
    }
  };

  return (
    <Container className='py-3'>
      <div className='button-container mb-4'>
        <Button
          variant='primary'
          onClick={() => handleNewChatSetting()}
          className='d-flex align-items-center'
        >
          <span className='material-symbols-outlined me-2'>add</span>
          <span className='d-none d-lg-inline'>New Setting</span>
        </Button>
        <Button
          variant='outline-danger'
          onClick={() => confirmAndDelete('delete all chats with no settings')}
          className='d-flex align-items-center'
        >
          <span className='material-symbols-outlined me-2'>delete_sweep</span>
          <span className='d-none d-lg-inline'>
            Delete all chats with no settings
          </span>
        </Button>
        <Button
          variant='outline-danger'
          onClick={() => confirmAndDelete('delete all chats')}
          className='d-flex align-items-center'
        >
          <span className='material-symbols-outlined me-2'>delete</span>
          <span className='d-none d-lg-inline'>Delete All Chats</span>
        </Button>
      </div>
      {!loadingState && <ChatSettingsContainer />}
      {editingState && <PromptForm />}
    </Container>
  );
};

export default ChatSettings;
