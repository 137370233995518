import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import './NavBar.css';

const NavBar = () => {
  const { logOut } = useAuth();

  const logout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logOut();
  };

  return (
    <Navbar
      expand='lg'
      collapseOnSelect
      className='bg-body-tertiary'
      style={{ borderRadius: '4px' }}
    >
      <Container>
        <Navbar.Brand href='#home'>MultiGPT</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link href='/chat'>Chat</Nav.Link>
            <Nav.Link href='/chat-settings'>Settings</Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown title='Account Settings' id='collapsible-nav-dropdown'>
              <NavDropdown.Item href='/change-password'>
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='#' onClick={event => logout(event)}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
