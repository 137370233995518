import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import ChatPage from './pages/ChatPage/ChatPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ChatSettingsPage from './pages/ChatSettingsPage/ChatSettingsPage';
import { AuthProvider } from './contexts/AuthContext';
import { ErrorsProvider } from './contexts/ErrorsContext';
import PageAlert from './components/shared/PageAlert';
import HowToPage from './pages/HowToPage/HowToPage';
import { ChatProvider } from './contexts/ChatsContext';
import { ChatSettingsProvider } from './contexts/ChatSettingsContext';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';

function App() {
  return (
    <ErrorsProvider>
      <AuthProvider>
        <ChatSettingsProvider>
          <ChatProvider>
            <Router>
              <PageAlert />
              <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/chat' element={<ChatPage />} />
                <Route
                  path='/change-password'
                  element={<ChangePasswordPage />}
                />
                <Route path='/chat-settings' element={<ChatSettingsPage />} />
                <Route path='/tune-multi-gpt' element={<HowToPage />} />
                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </Router>
          </ChatProvider>
        </ChatSettingsProvider>
      </AuthProvider>
    </ErrorsProvider>
  );
}

export default App;
