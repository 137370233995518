import React, { createContext, ReactNode, useContext, useState } from 'react';
import { IError, IErrorsContext } from '../types/apiPayloads';

const ErrorsContext = createContext<IErrorsContext>({} as IErrorsContext);

function ErrorsProvider({ children }: { children: ReactNode }) {
  const [apiError, setErrorState] = useState<IError>({ message: '', name: '' });

  const setApiError = (apiError: IError) => setErrorState(apiError);
  const clearError = () => setErrorState({ message: '', name: '' });

  return (
    <ErrorsContext.Provider value={{ apiError, setApiError, clearError }}>
      {children}
    </ErrorsContext.Provider>
  );
}

function useErrors(): IErrorsContext {
  const context = useContext(ErrorsContext);
  if (!context) {
    throw new Error('useErrors must be used within an ErrorsProvider');
  }
  return context;
}

export { ErrorsProvider, useErrors };
