import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/shared/Header';
import LoginForm from '../../components/LoginForm/LoginForm';
import useErrorState from '../../hooks/useErrorState';
import { useAuth } from '../../contexts/AuthContext';

const LoginPage = () => {
  const [authError, setAuthError] = useErrorState({ message: '', name: '' });
  const { redirectToChat, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn()) {
      redirectToChat();
    }
  }, []);

  useEffect(() => {
    const error = localStorage.getItem('authError');
    if (error) {
      setAuthError({ message: error, name: 'authError' });
      localStorage.removeItem('authError');
    }
  }, [setAuthError]);

  return (
    <Container style={{ marginTop: '50px' }}>
      <Header
        header='Welcome to MultiGPT!'
        subheader='Your customizable AI chat assistant.'
      />
      {authError.message && <Alert variant='danger'>{authError.message}</Alert>}
      <Row className='justify-content-center'>
        <Col sm={10} lg={4} style={{ marginTop: '50px' }}>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
