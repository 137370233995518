import { Nav } from 'react-bootstrap';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserMenu.css';

const UserMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Nav activeKey='/' className='flex-column mt-2'>
      <Nav.Item>
        <Nav.Link
          href='#'
          onClick={() => navigate('/chat-settings')}
          className={
            location.pathname === '/chat-settings'
              ? 'user-menu-active'
              : 'user-menu'
          }
        >
          Chat Settings
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          href='#'
          onClick={() => navigate('/tune-multi-gpt')}
          className={
            location.pathname === '/tune-multi-gpt'
              ? 'user-menu-active'
              : 'user-menu'
          }
        >
          How to MultiGPT
        </Nav.Link>
      </Nav.Item>
      {/*<Nav.Item>*/}
      {/*  <Nav.Link eventKey='disabled' disabled>*/}
      {/*    Delete account*/}
      {/*  </Nav.Link>*/}
      {/*</Nav.Item>*/}
    </Nav>
  );
};

export default UserMenu;
