import React from 'react';
import _ from 'lodash';
import '../../App.css';

const JumpingDots = () => {
  return (
    <div className='jumping-dots'>
      {_.times(5, (i: number) => (
        <div key={i} className={`dot dot-${i + 1}`} />
      ))}
    </div>
  );
};

export default JumpingDots;
